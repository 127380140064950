<template>
  <div>
    <PageHeader>
      <h1 class="heading">
        Chat
      </h1>
    </PageHeader>

    <div style="font-size: 48px;max-width: 520px" class="text-center mx-auto mt-16 py-16">
      Chat page <br> is under construction
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader";

export default {
  name: 'ChatPage',
  components: {
    PageHeader,
  },
}
</script>
